import React, { useState, useEffect } from 'react';
import Player from './components/Player';
import LoginForm from './components/LoginForm';
import './App.css';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate session check (can be replaced with an actual API call)
        const checkSession = async () => {
            try {
                const response = await fetch('/api/check-session');
                const { authenticated } = await response.json();
                setIsLoggedIn(authenticated);
            } catch (error) {
                console.error('Error checking session:', error);
            } finally {
                setLoading(false);
            }
        };

        checkSession();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div id="root" className="app-container">
            {isLoggedIn ? (
                <>
                    <button onClick={() => setIsLoggedIn(false)}>Logout</button>
                    <Player />
                </>
            ) : (
                <>
                    <LoginForm onLogin={() => setIsLoggedIn(true)} />
                    <Player />
                </>
            )}
        </div>
    );
}

export default App;

